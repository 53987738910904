@use '@styles/theme.module.scss' as *;

.container {
  align-content: center;

  &.one_pill {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.container_toggle {
  display: flex;
  align-items: center;
  gap: 20px;
}

.check {
  position: relative;
  height: 100%;
}

.label_prefix {
  margin-right: spacing(2);
  @include typographyBodyMedium;

  @include media($until: $md) {
    display: none;
  }
}

.check_background {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: spacing(3) spacing(6);
  border: 1px solid $grayLight;
  border-radius: 2.5rem;
  transition: all 0.25s ease-out, visibility 0s;
  background: $white;
  color: $deliveryPassFG;
  white-space: nowrap;
  word-wrap: normal;
  @include typographySmallBody;

  > .label_prefix {
    color: $white;
  }

  &.disabled {
    cursor: not-allowed;
  }

  @include media($from: $md) {
    @include typographyBody;
  }
}

.check_background_toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 34px;
  width: 68px;
  border: 1px solid $grayLighter;
  border-radius: 2.5rem;
  transition: all 0.25s ease-out, visibility 0s;
  background-color: $grayLighter;
  color: $deliveryPassFG;
  white-space: nowrap;
  word-wrap: normal;
  @include typographySmallBody;

  > .label_prefix {
    color: $white;
  }

  &.disabled {
    cursor: not-allowed;
  }

  @include media($from: $md) {
    @include typographyBody;

    margin-top: spacing(1);
  }
}

.toggle_icon {
  svg {
    transform: scale(1.5);
  }
}

.check_background_toggle::before {
  content: '';
  height: 30px;
  width: 30px;
  left: 2px;
  bottom: 2px;
  border-radius: 34px;
  box-shadow: 0 3px 1px 0 #0000000f;
  box-shadow: 0 3px 8px 0 #00000026;
  background-color: $white;
  transition: 0.3s;
}

.check:hover .check_background,
.check:hover .check_background_toggle {
  border-color: $black;
}

.check_input:focus ~ .check_background,
.container:focus-within .check_background {
  outline-offset: spacing(1);
  outline: 1px dashed $grayDarker;
}

.check:focus-within .check_background_toggle {
  outline: 1px dashed $grayDarker;
  outline-offset: 2px;
}

.check_input:checked ~ .check_background,
.check_input:checked ~ .check_background_toggle {
  background-color: $deliveryPassFG;
  border-color: $deliveryPassFG;
}

.check_input:checked ~ .check_background path {
  fill: $white;
}

input:checked + .check_background_toggle::before {
  transform: translate(35px);
}
